import { api } from "app/config/api";
import axios from "axios";

export const getDanhSachChiTieuDashboard = (data) => {
  console.log("data",data);
  const filteredObject = removeNullFields({ ...objectTemp(data) });
  const objString = '?' + new URLSearchParams(filteredObject).toString()
  return new Promise((resolve, reject) => {
    axios
      .get(api.API_DASHBOARD + '/thong-tin-so-lieu' + objString)
      .then((results) => {
        resolve(results?.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getChartTiLeBoiThuongDashboard = (data) => {
  const filteredObject = removeNullFields({ ...objectTemp(data) });
  const objString = '?' + new URLSearchParams(filteredObject).toString()
  return new Promise((resolve, reject) => {
    axios
      .get(api.API_DASHBOARD + '/ti-le-boi-thuong' + objString)
      .then((results) => {
        resolve(results?.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getChartHoSoDuyetYeuCauDashboard = (data) => {
  const filteredObject = removeNullFields({ ...objectTemp(data) });
  const objString = '?' + new URLSearchParams(filteredObject).toString()
  return new Promise((resolve, reject) => {
    axios
      .get(api.API_DASHBOARD+ '/thong-tin-ho-so-theo-thang' + objString)
      .then((results) => {
        resolve(results?.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getChartTMCSKCBDashboard = (data) => {
  const filteredObject = removeNullFields({ ...objectTemp(data) });
  const objString = '?' + new URLSearchParams(filteredObject).toString()
  return new Promise((resolve, reject) => {
    axios
      .get(api.API_DASHBOARD+ '/top-10-cskcb' + objString)
      .then((results) => {
        resolve(results?.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getChartTMDMICDDashboard = (data) => {
  const filteredObject = removeNullFields({ ...objectTemp(data) });
  const objString = '?' + new URLSearchParams(filteredObject).toString()
  return new Promise((resolve, reject) => {
    axios
      .get(api.API_DASHBOARD+ '/top-10-dm-icd' + objString)
      .then((results) => {
        resolve(results?.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

const objectTemp = (data) =>{
  return {
    ...data,
    dsHopDong: data?.dsHopDong?.map((item) => item.value),
    congTyBaoHiem: data?.congTyBaoHiem?.value,
    chuHopDong: data?.chuHopDong?.value,
    chuongTrinhBaoHiem: data?.chuongTrinhBaoHiem?.id,
    kenhKhaiThac: data?.kenhKhaiThac?.value,
    coSoYTe: data?.coSoYTe?.value,
    trangThaiHD: data?.trangThaiHD?.value,
    loaiHinhBoiThuong: data?.loaiHinhBoiThuong?.value,
    loaiKhachHang: data?.loaiKhachHang?.value,
    phuongThucNhanHoSo: data?.phuongThucNhanHoSo?.value,
    vip: data?.vip?.value,
    nhomBenh: data?.nhomBenh?.map((item) => item.value),
    gioiTinh: data?.gioiTinh?.value,
    khuVucThuongTru: data?.khuVucThuongTru?.value,
    tinhTrangHoSo: data?.tinhTrangHoSo?.value,
  }
} 


function removeNullFields(obj: any) {
  const filteredObj: any = {}
  for (const key in obj) {
    const value = obj[key]
    if (
      value !== null &&
      value !== 'null' &&
      value !== '' &&
      value !== 'all' &&
      value !== undefined &&
      !(Array.isArray(value) && value.every((item: any) => item === undefined ||  item === null ))
    ) {
      filteredObj[key] = value
    }
  }

  return filteredObj
}