import { call, put, takeEvery } from "redux-saga/effects"
import { dashboardActions } from "./dashboardSlice"
import { getChartHoSoDuyetYeuCauDashboard, getChartTiLeBoiThuongDashboard, getChartTMCSKCBDashboard, getChartTMDMICDDashboard, getDanhSachChiTieuDashboard } from "./service"
import { handleError } from "_metronic/helpers"

function* handleGetDanhSachChiTieuDashboard({ payload }) {
  try {
    const resultsDanhSachChiTieuDashboard = yield call(getDanhSachChiTieuDashboard, payload)
    if (resultsDanhSachChiTieuDashboard?.data) {
      yield put({
        type: dashboardActions.getDanhSachChiTieuDashboardSuccess.type,
        payload: resultsDanhSachChiTieuDashboard?.data,
      })
    }
  } catch (e) {
    yield put({
      type: dashboardActions.getDanhSachChiTieuDashboardFailed.type,
    })
    handleError(e)
  }
}

function* handleGetChartTiLeBoiThuongDashboard({ payload }) {
  try {
    const resultsChartChiTieuDashboard = yield call(getChartTiLeBoiThuongDashboard, payload)
    if (resultsChartChiTieuDashboard?.data) {
      const dataResultsTemp = resultsChartChiTieuDashboard?.data
      yield put({
        type: dashboardActions.getChartTiLeBoiThuongDashboardSuccess.type,
        payload: {
          labels: dataResultsTemp?.map((e) => e?.label),
          dataSeries: {
            tongTienDuyet: dataResultsTemp?.map((e) => e?.data?.tongTienDuyet),
            tiLePhanTram: dataResultsTemp?.map((e) => e?.data?.tiLePhanTram && parseFloat(Number(e?.data?.tiLePhanTram).toFixed(2))),
            phanTramDuyet: dataResultsTemp?.map((e) => e?.data?.phanTramDuyet && parseFloat(Number(e?.data?.phanTramDuyet).toFixed(2))),
            tongHoSo: dataResultsTemp?.map((e) => e?.data?.tongHoSo)
          }
        },
      })
    }
  } catch (e) {
    yield put({
      type: dashboardActions.getChartTiLeBoiThuongDashboardFailed.type,
    })
    handleError(e)
  }
}

function* handleGetChartThongTinDuyetYeuCauDashboard({ payload }) {
  try {
    const result = yield call(getChartHoSoDuyetYeuCauDashboard, payload)
    if (result?.data) {
      const dataResultsTemp = result?.data
      yield put({
        type: dashboardActions.getChartThongTinDuyetYeuCauDashboardSuccess.type,
        payload: {
          labels: dataResultsTemp?.map((e) => e?.label),
          dataSeries: {
            chartTongTienHoSoDuyetVaYeuCau: [
              {
                name: 'Đã duyệt',
                type: 'column',
                data: dataResultsTemp?.map((e) => e?.data?.tongTienHoSoDuyet),
              },
              {
                name: 'Từ chối',
                type: 'column',
                data: dataResultsTemp?.map((e) => e?.data?.tongTienHoSoTuChoi),
              },
              {
                name: 'Tổng yêu cầu',
                type: 'line',
                data: dataResultsTemp?.map((e) => e?.data?.tongVienPhiYeuCau)
              }
            ],
            chartSoHoSoDuyetVaYeuCau: [
              {
                name: 'Đã duyệt',
                type: 'column',
                data: dataResultsTemp?.map((e) => e?.data?.tongHoSoDuyet),
              },
              {
                name: 'Từ chối',
                type: 'column',
                data: dataResultsTemp?.map((e) => e?.data?.tongHoSoTuChoi),
              },
              {
                name: 'Tổng yêu cầu',
                type: 'line',
                data: dataResultsTemp?.map((e) => e?.data?.tongHoSoYeuCau)
              }
            ]
          }
        },
      })
    }
  } catch (e) {
    yield put({
      type: dashboardActions.getChartThongTinDuyetYeuCauDashboardFailed.type,
    })
    handleError(e)
  }
}

function* handleGetChartTMCSKCBDashboard({ payload }) {
  try {
    const resultsChartTMCSKCBDashboard = yield call(getChartTMCSKCBDashboard, payload)
    if (resultsChartTMCSKCBDashboard?.data) {
      const dataResultsTemp = resultsChartTMCSKCBDashboard?.data
      const arrayHoSo = dataResultsTemp?.map((e) => e?.data?.tongHoSo)
      const arrayVienPhi = dataResultsTemp?.map((e) => e?.data?.tongVienPhi)
      yield put({
        type: dashboardActions.getChartTMCSKCBDashboardSuccess.type,
        payload: {
          labels: dataResultsTemp?.map((e) => e?.data?.maCskcb),
          dataSeries: {
            chart:[
              {
                name: 'Số tiền',
                type: 'column',
                data: arrayVienPhi
              },
              {
                name: 'Số hồ sơ',
                type: 'line',
                data: arrayHoSo
              }
            ],
            maCskcb: dataResultsTemp?.map((e) => e?.data?.maCskcb),
            tenCskcb: dataResultsTemp?.map((e) => e?.data?.tenCskcb),
            tongHoSo: arrayHoSo,
            tongVienPhi: arrayVienPhi
          }
        },
      })
    }
  } catch (e) {
    yield put({
      type: dashboardActions.getChartTMCSKCBDashboardFailed.type,
    })
    handleError(e)
  }
}

function* handleGetChartTMDMICDDashboard({ payload }) {
  try {
    const resultsChartTMDMICDDashboard = yield call(getChartTMDMICDDashboard, payload)
    if (resultsChartTMDMICDDashboard?.data) {
      const dataResultsTemp = resultsChartTMDMICDDashboard?.data
      const arrayTongHoSo = dataResultsTemp?.map((e) => e?.data?.tongHoSo)
      const arrayMaICD = dataResultsTemp?.map((e) => e?.data?.maICD)
      yield put({
        type: dashboardActions.getChartTMDMICDDashboardSuccess.type,
        payload: {
          labels: arrayMaICD,
          dataSeries: {
            chart: arrayTongHoSo,
            tongHoSo: arrayTongHoSo,
            tenICD: dataResultsTemp?.map((e) => e?.data?.tenICD),
            tongVienPhi: dataResultsTemp?.map((e) => e?.data?.tongVienPhi),
            maICD: arrayMaICD,
          }
        },
      })
    }
  } catch (e) {
    yield put({
      type: dashboardActions.getChartTMDMICDDashboardFailed.type,
    })
    handleError(e)
  }
}

export default function* dashboardSaga() {
  yield takeEvery(dashboardActions.getDanhSachChiTieuDashboard.type, handleGetDanhSachChiTieuDashboard);
  yield takeEvery(dashboardActions.getChartTiLeBoiThuongDashboard.type, handleGetChartTiLeBoiThuongDashboard);
  yield takeEvery(dashboardActions.getChartThongTinDuyetYeuCauDashboard.type, handleGetChartThongTinDuyetYeuCauDashboard);
  yield takeEvery(dashboardActions.getChartTMCSKCBDashboard.type, handleGetChartTMCSKCBDashboard);
  yield takeEvery(dashboardActions.getChartTMDMICDDashboard.type, handleGetChartTMDMICDDashboard);
}
