import axios, {AxiosResponse} from 'axios'
import {api} from 'app/config/api'

import {NewsBodyRequest, NewsExportParamRequest, NewsListParamRequest} from './request.model'
import {
  ApiResponse,
  GetNewsDetailReponse,
  NewsUploadImageResponse,
  NewsUploadAvatarResponse,
  NewsListResponse,
} from './response.model'

export const NewsServices = {
  async uploadImage(file: File): Promise<AxiosResponse<NewsUploadImageResponse>> {
    const formData = new FormData()
    formData.append('file', file)

    return await axios.put(api.API_NEWS_UPLOAD_FILE, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: '',
      },
    })
  },
  async uploadAvatar(file: File): Promise<AxiosResponse<NewsUploadAvatarResponse>> {
    const formData = new FormData()
    formData.append('file', file)

    return await axios.put(api.API_NEWS_UPLOAD_AVATAR, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: '',
      },
    })
  },
  async createNews(body?: NewsBodyRequest): Promise<AxiosResponse<GetNewsDetailReponse>> {
    return await axios.post(api.API_NEWS, body)
  },
  async updateNews(
    id: string,
    body?: NewsBodyRequest
  ): Promise<AxiosResponse<GetNewsDetailReponse>> {
    return await axios.put(api.API_NEWS + `/${id}`, body)
  },
  async deleteNewById(id: string): Promise<AxiosResponse<ApiResponse>> {
    return await axios.delete(api.API_NEWS + `/${id}`)
  },
  async getNewById(id: string): Promise<AxiosResponse<GetNewsDetailReponse>> {
    return await axios.get(api.API_NEWS + `/${id}`)
  },
  async getNews(params: NewsListParamRequest): Promise<AxiosResponse<NewsListResponse>> {
    return await axios.get(api.API_NEWS, {params})
  },
  async getNewsExport(params: NewsExportParamRequest): Promise<AxiosResponse> {
    return await axios.get(api.API_NEWS_EXPORT, {params, responseType: 'blob'})
  },
}
