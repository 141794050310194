import {call, put, takeLatest} from 'redux-saga/effects'
import {PayloadAction} from '@reduxjs/toolkit'
import {AxiosResponse} from 'axios'
import {isEqual} from 'lodash'

import ToastMessage from 'app/components/ToastMessage'

import {manageNewsEventsActions} from '../slice'
import {NewsServices} from '../services'
import {NewsListParamRequest} from '../services/request.model'
import {NewsListResponse} from '../services/response.model'

function* getNewsList(action: PayloadAction<NewsListParamRequest>) {
  try {
    const newPayload: NewsListParamRequest = action.payload

    if (newPayload?.isFilter) {
      delete newPayload.isFilter
    }

    newPayload.page = newPayload.page / newPayload.perPage

    const response: AxiosResponse<NewsListResponse> = yield call(NewsServices.getNews, newPayload)

    if (response && isEqual(response.status, 200)) {
      yield put(manageNewsEventsActions.getListNewsSuccess(response.data))
    }
  } catch (error) {
    yield put(manageNewsEventsActions.getListNewsFail())
    ToastMessage('error', '')
  }
}

export default function* manageNewsEventsSaga() {
  yield takeLatest(manageNewsEventsActions.getListNewsRequest, getNewsList)
}
