import {FC} from 'react'
import {useLang} from './Metronici18n'
import {IntlProvider} from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import enMessages from './messages/en.json'
import viMessages from './messages/vi.json'

import viSystem from '../../app/modules/system/locale/vi.json'
import enSystem from '../../app/modules/system/locale/en.json'

import {WithChildren} from '../helpers'

const allMessages = {
  vi: {...viMessages, ...viSystem},
  en: {...enMessages, ...enSystem},
}

const I18nProvider: FC<WithChildren> = ({children}) => {
  const locale = useLang()
  const messages = allMessages[locale]

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  )
}

export {I18nProvider}
