import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {NewsListParamRequest} from '../services/request.model'
import {NewsListResponse} from '../services/response.model'

export interface ManageNewsEventsSliceType {
  isLoading?: boolean
  request?: NewsListParamRequest
  response?: NewsListResponse
}

const initialState: ManageNewsEventsSliceType = {}

export const manageNewsEventsSlice = createSlice({
  name: 'manageNewsEvents',
  initialState,
  reducers: {
    getListNewsRequest(state, action: PayloadAction<NewsListParamRequest>) {
      return {
        ...state,
        request: {
          ...state.request,
          ...action.payload,
        },
        isLoading: true,
      }
    },
    getListNewsSuccess(state, action: PayloadAction<NewsListResponse>) {
      return {
        ...state,
        response: {
          ...action.payload,
        },
        isLoading: false,
      }
    },
    getListNewsFail(state) {
      return {
        ...state,
        isLoading: false,
      }
    },
  },
})

export const manageNewsEventsActions = manageNewsEventsSlice.actions
const manageNewsEventsReducer = manageNewsEventsSlice.reducer

export default manageNewsEventsReducer
