import { api } from 'app/config/api'
import { AuthModel } from './_models'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'

const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any, functionReload: any) {
  axios.defaults.headers.Accept = 'application/json'
  // axios.defaults.headers.common['Local-Ip'] = '123' && !config?.url?.includes('DuTruBaoLanh');
  axios.interceptors.request.use(
    (config: { headers: { Authorization: string } }) => {
      const auth = getAuth()
      if (
        auth &&
        auth.access_token &&
        !config?.url?.includes('DuTruBaoLanh') &&
        !config.url?.includes(api.SUPERSET_URL)
      ) {
        config.headers.Authorization = `Bearer ${auth.access_token}`
      }
      return config
    },
    (err: any) => Promise.reject(err)
  )

  axios.interceptors.response.use(
    (response: any) => {
      return response
    },
    async (error: any) => {
      const status = error.response ? error.response.status : null
      if (status == 401) {
        functionReload()
        localStorage.setItem('sessionTimeout', 'true')
      }
      if (status == 403) {
        window.location.href = `/error/403`
      }
      if (status == 404) {
        window.location.href = `/error/404`
      }
      return Promise.reject(error)
    }
  )
}

export { AUTH_LOCAL_STORAGE_KEY, getAuth, removeAuth, setAuth }
