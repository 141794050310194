import { setPageTitle } from '_metronic/helpers/CommonHelpers'
import { useEffect, useState } from 'react'
import 'app/modules/statistic/assets/scss/index.scss'
import SupersetDashboard from 'app/components/dashboard/SupersetDashboard'
import DatePicker from 'react-datepicker'
import MaskedInput from 'react-text-mask'
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'
import { useAuth } from 'app/pages/auth'
import Select from 'react-select'
import { useSelector } from 'react-redux'
import {
  categoryActions,
  selectListInsuranceCompany,
} from 'app/modules/category/redux/category/categorySlice'
import { useDispatch } from 'react-redux'
import { api } from 'app/config/api'
import axios from 'axios'
import { handleError, toAbsoluteUrl, useDebounce } from '_metronic/helpers'
import { useNavigate } from 'react-router-dom'
import './style.scss'
import { getStartOfDay, getEndOfDay } from 'app/utils/date'
export const MainDashboard = () => {
  const navigate = useNavigate()

  const listInsuranceCompany: any = useSelector(selectListInsuranceCompany)
  const [fromDate, setFromDate] = useState<number>(
    new Date(2024, 6, 14).getTime()
  );
  const maxDate: any = new Date().getTime()
  const [toDate, setToDate] = useState<any>(getEndOfDay().getTime())
  const [donViBH, setDonViBH] = useState<any>()
  const [page] = useState(0)
  const [itemsPerPage] = useState(200)
  const [messagErrorTime, setMessageErrorTime] = useState<any>('')
  const [messagErrorSelectCongTyBH, setMessagErrorSelectCongTyBH] = useState<any>('')
  const [messagErrorSelectMaHopDong, setMessagErrorSelectMaHopDong] = useState<any>('')
  const [showOptionMaHD, setShowOptionMaHD] = useState<any>(false)
  const [listStringSelected, setListStringSelected] = useState<any>([])
  const [loadingSelect, setLoadingSelect] = useState<any>(false)
  const [searchKey, setSearchKey] = useState('')
  const [totalLength, setTotalLength] = useState<any>('')
  const [pageCommon, setPageCommon] = useState(page)
  const [danhSach, setDanhSach] = useState<any>([])
  const [idCongTyKhachHang, setIdCongTyKhachHang] = useState<any>([])
  const [idSuperset, setIdSuperset] = useState<any>([])
  const debouncedSearchNo = useDebounce(searchKey, 300)
  const { currentUser } = useAuth()
  const dispatch = useDispatch()
  const [dashboardReady, setDashboardReady] = useState(false)
  useEffect(() => {
    setPageTitle('Dashboard')
    dispatch(categoryActions.getListInsuranceCompany(null))
  }, [])
  console.log("fromDate" ,fromDate, "toDate:",  toDate)
  useEffect(() => {
    const listInsuranceCompanySelected = listInsuranceCompany.find(
      (e: any) => e?.value === currentUser?.attributes?.maDonVi[0]
    )
    setDonViBH(listInsuranceCompanySelected)
    setShowOptionMaHD(true)
    setListStringSelected([null])
  }, [listInsuranceCompany])
  useEffect(() => {
    if (currentUser?.attributes?.maDonVi[0] && currentUser?.attributes?.type[0]) {
      if (currentUser?.attributes?.type[0] === '2') {
        axios
          .get(`${api.API_CATEGOTY_DOANH_NGHIEP}/${currentUser?.attributes?.maDonVi[0]}/get-all`)
          .then(({ data }) => {
            console.log(data)
            const ids: any = data.map((item: any) => item.id)
            setIdCongTyKhachHang(ids)
          })
          .catch((error: any) => {
            handleError(error)
          })
          .finally(() => {
            setLoadingSelect(false)
          })
        setIdSuperset('587d4875-0d75-4da0-8e2d-09f74153230d')
        setDashboardReady(true)
      } else {
        setIdSuperset('0d6376d7-fbf1-4170-96d5-22d2d4955e8c')
        setDashboardReady(true)
      }
    }
  }, [currentUser?.attributes?.maDonVi[0], currentUser?.attributes?.type[0]])
  useEffect(() => {
    if (donViBH) {
      fetchDanhSach()
    }
  }, [donViBH, itemsPerPage, pageCommon])

  useEffect(() => {
    if (searchKey) {
      fetchDanhSach()
    }
  }, [debouncedSearchNo])

  const fetchDanhSach = async () => {
    let params: any = ''
    params = `?page=${page}&itemsPerPage=${itemsPerPage}&search=${searchKey}&idDonViQuanLy=${donViBH?.value}`
    setLoadingSelect(true)
    await axios
      .get(`${api.API_DANH_SACH_SO_HOP_DONG_BH_FILTER_THEO_CONG_TY_BAO_HIEM}${params}`)
      .then(({ data }) => {
        setTotalLength(data?.total)
        if (pageCommon > 0) {
          setDanhSach([...danhSach, ...data.results])
        } else {
          let listUnshiftAll = []
          listUnshiftAll = [...data?.results]
          listUnshiftAll.unshift({
            label: 'Tất cả',
            value: null,
          })
          setDanhSach(listUnshiftAll)
        }
      })
      .catch((error: any) => {
        handleError(error)
      })
      .finally(() => {
        setLoadingSelect(false)
      })
  }
  const onChange = (e: any) => {
    console.log(e)
    let arrayLoc: any = []
    const value = e?.map((element: any) => element?.value)

    const hasNull = value[value.length - 1] === null
    hasNull
      ? (arrayLoc = value.filter((element: any) => element == null))
      : (arrayLoc = value.filter((element: any) => element != null))
    setListStringSelected(arrayLoc)
  }
  useEffect(() => {
    const selectedContracts = danhSach.filter((element: any) =>
      listStringSelected.includes(element.value)
    );

    if (selectedContracts.length > 0) {
      const dates = selectedContracts
        .map((hd: any) => ({
          fromDate: hd.hieuLucTuNgay ? hd.hieuLucTuNgay : null,
          toDate: hd.hieuLucDenNgay ? hd.hieuLucDenNgay : null,
        }))
        .filter((d: any) => d.fromDate && d.toDate);

      if (dates.length > 0) {
        const minFromDate = Math.min(...dates.map((d: any) => d.fromDate));
        const maxToDate = Math.max(...dates.map((d: any) => d.toDate));
        setFromDate(new Date(minFromDate));
        setToDate(new Date(maxToDate));
      }
    }
  }, [danhSach, listStringSelected]);


  const handlePageChangeCommon = () => {
    if (
      Math.floor(totalLength / itemsPerPage) === pageCommon ||
      pageCommon < Math.floor(totalLength / itemsPerPage)
    ) {
      if (Math.floor(totalLength / itemsPerPage) !== 0) {
        setPageCommon((prevPage: any) => prevPage + 1)
      }
    }
  }
  console.log(idSuperset)
  return (
    <>
      <div className='filter-dashdoard'>
        <div className='my-5'>
          <div className='ms-10' style={{ display: 'flex' }}>
            <div
              className='col-2'
              style={{ display: 'flex', alignItems: 'end', justifyContent: 'center' }}
            >
              <button
                className='btn btn-primary'
                type='button'
                onClick={() => navigate('/apps/account/overview')}
              >
                <img src={toAbsoluteUrl('/media/common/icon-home.svg')} alt='' />
                <span style={{ paddingLeft: 5 }}>Trang chủ</span>
              </button>
            </div>
            <div className='col-10 d-flex'>
              {currentUser?.attributes?.type[0] !== '2' ? (
                <>
                  <div
                    className='col-4'
                    style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}
                  >
                    <div
                      className='required col-10 label-filter-dashboard'
                      style={{
                        fontWeight: '900',
                        fontSize: '16px',
                        alignContent: 'center',
                      }}
                    >
                      Công ty bảo hiểm :
                    </div>
                    <div
                      className='col-10'
                      style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
                    >
                      <Select
                        menuPosition='fixed' // Hiển thị menu cố định trên vị trí nguồn gốc
                        menuPlacement='auto' // Tự động xác định vị trí hiển thị
                        styles={{
                          menu: (base: any) => ({
                            ...base,
                            zIndex: 9999, // Đặt z-index cao hơn
                          }),
                        }}
                        value={donViBH}
                        options={listInsuranceCompany}
                        classNamePrefix='select'
                        //isMulti
                        onChange={(selectedOptions: any) => {
                          setDonViBH(selectedOptions)
                          if (selectedOptions) {
                            setShowOptionMaHD(true)
                            setListStringSelected([])
                          } else setShowOptionMaHD(false)
                        }}
                        isSearchable
                        placeholder='Tìm kiếm'
                        className='fw-normal w-100'
                        isClearable
                        isDisabled={
                          currentUser?.attributes?.isTPA &&
                          currentUser?.attributes?.isTPA[0] === 'false'
                        }
                      ></Select>
                      {messagErrorSelectCongTyBH && !donViBH && donViBH !== undefined && (
                        <div style={{ color: 'red', display: 'flex' }}>
                          {messagErrorSelectCongTyBH}
                        </div>
                      )}
                    </div>
                  </div>

                  <>
                    <div
                      className='col-4'
                      style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}
                    >
                      <div className='col-12 d-flex'>
                        <div
                          className='col-10 required label-filter-dashboard'
                          style={{
                            fontWeight: '900',
                            fontSize: '16px',
                          }}
                        >
                          {' '}
                          Hợp đồng:
                        </div>
                      </div>
                      <div
                        className='col-10'
                        style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
                      >
                        <Select
                          menuPosition='fixed' // Hiển thị menu cố định trên vị trí nguồn gốc
                          menuPlacement='auto' // Tự động xác định vị trí hiển thị
                          styles={{
                            menu: (base: any) => ({
                              ...base,
                              zIndex: 9999, // Đặt z-index cao hơn
                            }),
                          }}
                          value={danhSach.filter((element: any) =>
                            listStringSelected.includes(element.value)
                          )}
                          isLoading={loadingSelect}
                          options={danhSach}
                          classNamePrefix='select'
                          isMulti
                          onChange={(e: any) => onChange(e)}
                          isSearchable
                          placeholder='Tìm kiếm'
                          className='fw-normal w-100'
                          isClearable
                          onMenuScrollToBottom={handlePageChangeCommon}
                          noOptionsMessage={() => ''}
                          onInputChange={(values: any) => {
                            setSearchKey(values)
                          }}
                          isDisabled={!showOptionMaHD}
                          onMenuOpen={async () => {
                            await setPageCommon(0)
                            await setSearchKey('')
                            fetchDanhSach()
                          }}
                        ></Select>
                        {messagErrorSelectMaHopDong && (
                          <div style={{ color: 'red', display: 'flex' }}>
                            {messagErrorSelectMaHopDong}
                          </div>
                        )}
                      </div>
                    </div>
                  </>

                </>
              ) : null}
              <div className='col-4' style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                <div
                  className='required col-10 label-filter-dashboard'
                  style={{
                    fontWeight: '900',
                    fontSize: '16px',
                  }}
                >
                  Thời gian:
                </div>
                <div className='col-10'>
                  <div className='d-flex'>
                    <div className='col-6'>
                      <DatePicker
                        className='form-control'
                        selected={fromDate ? fromDate : null}
                        onChange={(date) => setFromDate(date)}
                        dateFormat='dd/MM/yyyy'
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode='select'
                        calendarClassName='custom-style '
                        placeholderText={'Chọn từ ngày'}
                        isClearable={true}
                        customInput={
                          <MaskedInput
                            pipe={createAutoCorrectedDatePipe('dd/MM/yyyy')}
                            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                            keepCharPositions={true}
                            guide={true}
                            value={`${new Date(fromDate).getDate()}/${new Date(fromDate).getMonth() + 1
                              }/${new Date(fromDate).getFullYear()}`}
                          />
                        }
                      />
                    </div>
                    <div className='col-6' style={{ paddingLeft: 15 }}>
                      <DatePicker
                        className='form-control'
                        selected={toDate ? toDate : null}
                        onChange={(date) => setToDate(date)}
                        dateFormat='dd/MM/yyyy'
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode='select'
                        calendarClassName='custom-style '
                        maxDate={maxDate}
                        placeholderText={'Chọn đến ngày'}
                        isClearable={true}
                        customInput={
                          <MaskedInput
                            pipe={createAutoCorrectedDatePipe('dd/MM/yyyy')}
                            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                            keepCharPositions={true}
                            guide={true}
                            value={`${new Date(toDate).getDate()}/${new Date(toDate).getMonth() + 1
                              }/${new Date(toDate).getFullYear()}`}
                          />
                        }
                      />
                    </div>{' '}
                  </div>
                  {messagErrorTime && (
                    <div style={{ color: 'red', display: 'flex', paddingTop: 10 }}>
                      {messagErrorTime}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {dashboardReady && (
          <SupersetDashboard
            fromDate={fromDate}
            toDate={toDate}
            idDonViBaoHiem={currentUser?.attributes?.maDonVi[0]}
            idDonVi={donViBH?.value}
            maHopDong={listStringSelected}
            setMessageErrorTime={(value: any) => {
              setMessageErrorTime(value)
            }}
            setMessagErrorSelectCongTyBH={(value: any) => {
              setMessagErrorSelectCongTyBH(value)
            }}
            setMessagErrorSelectMaHopDong={(value: any) => {
              setMessagErrorSelectMaHopDong(value)
            }}
            type={currentUser?.attributes?.type[0]}
            idCongTyKhachHang={idCongTyKhachHang}
            idSuperset={idSuperset}
          />
        )}
      </div>
    </>
  )
}
