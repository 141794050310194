import { createSlice } from '@reduxjs/toolkit'
import { FilterData } from '../../pages/dashboard/variables/interface';


interface DashboardState {
  objectSearch: FilterData;
  loadingDanhSachChiTieuDashboard: boolean;
  danhSachChiTieuDashboard: any;
  chartTiLeBoiThuongDashboard: any;
  loadingChartTiLeBoiThuongDashboard: boolean;
  chartSoHoSoTienDuyetVaYeuCau:any;
  loadingChartSoHoSoTienDuyetVaYeuCau: boolean;
  chartTMDMICDDashboard:any;
  loadingChartTMDMICDDashboard:any;
  chartTMCSKCBDashboard:any;
  loadingChartTMCSKCBDashboard:any;
  objectSearchBeforePopupFilter : FilterData | null;
  activeFilter:boolean
}

const getDefaultDateRange = () => {
  const now = new Date();
  // Milisecond đầu tiên của 3 tháng trước
  const startDate = new Date(now.getFullYear(), now.getMonth() - 2, 1);
  startDate.setHours(0, 0, 0, 0);
  // Milisecond cuối cùng của tháng hiện tại
  const endDate = new Date(now.getFullYear(), now.getMonth() + 1, 1);
  endDate.setMilliseconds(-1);
  return [startDate.getTime(), endDate.getTime()];
};

const initialState : DashboardState = {
  objectSearch: {
    thangBaoCao: getDefaultDateRange(),
    congTyBaoHiem: null,
    ngayHieuLucHD:  [undefined, undefined],
    chuHopDong: null,
    ngayHieuLucBH:  [undefined, undefined],
    chuongTrinhBaoHiem: null,
    ngayNhanHoSoThucTe:  [undefined, undefined],
    kenhKhaiThac: null,
    ngayDuyetHoSo:  [undefined, undefined],
    coSoYTe: null,
    trangThaiHD: null,
    loaiHinhBoiThuong: null,
    loaiKhachHang: null,
    phuongThucNhanHoSo: null,
    vip: false,
    nhomBenh: null,
    gioiTinh: null,
    khuVucThuongTru: null,
    tuoiMuaBaoHiem: [undefined, undefined],
    tinhTrangHoSo: null,
    dsHopDong : null
  },
  loadingDanhSachChiTieuDashboard : true,
  danhSachChiTieuDashboard : null,
  chartTiLeBoiThuongDashboard:null,
  loadingChartTiLeBoiThuongDashboard: true,
  chartSoHoSoTienDuyetVaYeuCau:null,
  loadingChartSoHoSoTienDuyetVaYeuCau: true,
  chartTMDMICDDashboard:null,
  loadingChartTMDMICDDashboard:true,
  chartTMCSKCBDashboard:null,
  loadingChartTMCSKCBDashboard:true,
  objectSearchBeforePopupFilter:null,
  activeFilter:true
}

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setObjectSearch(state, action) {
      state.objectSearch = {...state.objectSearch, ...action.payload}
    },
    setObjectSearchToDefault(state) {
      state.objectSearch = initialState.objectSearch
    },
    setObjectSearchNoMerge(state,action){
      state.objectSearch = action.payload
    },
    getDanhSachChiTieuDashboard(state,payload) {
      state.loadingDanhSachChiTieuDashboard = true
    },
    getDanhSachChiTieuDashboardSuccess(state, action) {
      state.danhSachChiTieuDashboard = action.payload
      state.loadingDanhSachChiTieuDashboard = false
   },
    getDanhSachChiTieuDashboardFailed(state) {
      state.loadingDanhSachChiTieuDashboard = false
      state.danhSachChiTieuDashboard = null
    },
    getChartTiLeBoiThuongDashboard(state,payload) {
      state.loadingChartTiLeBoiThuongDashboard = true
    },
    getChartTiLeBoiThuongDashboardSuccess(state, action) {
      state.chartTiLeBoiThuongDashboard = action.payload
      state.loadingChartTiLeBoiThuongDashboard = false
    },
    getChartTiLeBoiThuongDashboardFailed(state) {
      state.loadingChartTiLeBoiThuongDashboard = false
      state.chartTiLeBoiThuongDashboard = null
    },
    getChartThongTinDuyetYeuCauDashboard(state,payload) {
      state.loadingChartSoHoSoTienDuyetVaYeuCau = true
    },
    getChartThongTinDuyetYeuCauDashboardSuccess(state, action) {
      state.chartSoHoSoTienDuyetVaYeuCau = action.payload
      state.loadingChartSoHoSoTienDuyetVaYeuCau = false
    },
    getChartThongTinDuyetYeuCauDashboardFailed(state) {
      state.loadingChartSoHoSoTienDuyetVaYeuCau = false
      state.chartSoHoSoTienDuyetVaYeuCau = null
    },
    getChartTMDMICDDashboard(state,payload) {
      state.loadingChartTMDMICDDashboard = true
    },
    getChartTMDMICDDashboardSuccess(state, action) {
      state.chartTMDMICDDashboard = action.payload
      state.loadingChartTMDMICDDashboard = false
    },
    getChartTMDMICDDashboardFailed(state) {
      state.loadingChartTMDMICDDashboard = false
      state.chartTMDMICDDashboard = null
    },
    getChartTMCSKCBDashboard(state,payload) {
      state.loadingChartTMCSKCBDashboard = true
    },
    getChartTMCSKCBDashboardSuccess(state, action) {
      state.chartTMCSKCBDashboard = action.payload
      state.loadingChartTMCSKCBDashboard = false
    },
    getChartTMCSKCBDashboardFailed(state) {
      state.loadingChartTMCSKCBDashboard = false
      state.chartTMCSKCBDashboard = null
    },
    setObjectSearchBeforePopupFilter(state,action){
      state.objectSearchBeforePopupFilter = action.payload
    },
    setActiveFilter(state,action){
      state.activeFilter = action.payload
    }
  }, 
})
export const dashboardActions = dashboardSlice.actions
const dashboardReducer = dashboardSlice.reducer

export default dashboardReducer

export const selectObjectSearchDashboard = (state:any) => state.dashboard.objectSearch
export const selectDanhSachChiTieuDashboard = (state:any) => state.dashboard.danhSachChiTieuDashboard
export const selectLoadingDanhSachChiTieuDashboard = (state:any) => state.dashboard.loadingDanhSachChiTieuDashboard
export const selectChartTiLeBoiThuongDashboard = (state:any) => state.dashboard.chartTiLeBoiThuongDashboard
export const selectLoadingChartTiLeBoiThuongDashboard = (state:any) => state.dashboard.loadingChartTiLeBoiThuongDashboard
export const selectChartSoHoSoTienDuyetVaYeuCau = (state:any) => state.dashboard.chartSoHoSoTienDuyetVaYeuCau
export const selectLoadingChartSoHoSoTienDuyetVaYeuCau = (state:any) => state.dashboard.loadingChartSoHoSoTienDuyetVaYeuCau
export const selectChartTMCSKCBDashboard = (state:any) => state.dashboard.chartTMCSKCBDashboard
export const selectLoadingChartTMCSKCBDashboard = (state:any) => state.dashboard.loadingChartTMCSKCBDashboard
export const selectChartTMDMICDDashboard = (state:any) => state.dashboard.chartTMDMICDDashboard
export const selectLoadingChartTMDMICDDashboard = (state:any) => state.dashboard.loadingChartTMDMICDDashboard
export const selectActiveFilter = (state:any) => state.dashboard.activeFilter
export const selectObjectSearchBeforePopupFilter = (state:any) => state.dashboard.objectSearchBeforePopupFilter







