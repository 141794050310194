import LoadingIndicator from 'app/components/loading'
import ToastMessage from 'app/components/ToastMessage'
import axios from 'axios'
import moment from 'moment'
import { Skeleton } from 'primereact/skeleton'
import qs from 'qs'
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState
} from 'react'
import ReactTooltip from 'react-tooltip'
import { errorCodeVariables } from './errorCodeVariables'
import { ID, QueryResponseContextProps, QueryState } from './models'
function createResponseContext<T>(initialState: QueryResponseContextProps<T>) {
  return createContext(initialState)
}

function isNotEmpty(obj: unknown) {
  // return obj !== undefined && obj !== null && obj !== "";
  return obj !== undefined && obj !== null
}

// Example: page=1&items_per_page=10&sort=id&order=desc&search=a&filter_name=a&filter_online=false
function stringifyRequestQuery(state: QueryState): string {
  const pagination = qs.stringify(state, {
    filter: ['page', 'items_per_page'],
    skipNulls: true,
  })
  const sort = qs.stringify(state, {
    filter: ['sort', 'order'],
    skipNulls: true,
  })
  const search = isNotEmpty(state.search)
    ? qs.stringify(state, { filter: ['search'], skipNulls: true })
    : ''

  const filter = state.filter
    ? Object.entries(state.filter as Object)
      .filter((obj) => isNotEmpty(obj[1]))
      .map((obj) => {
        return `filter_${obj[0]}=${obj[1]}`
      })
      .join('&')
    : ''

  return [pagination, sort, search, filter].filter((f) => f).join('&')
}

function parseRequestQuery(query: string): QueryState {
  const cache: unknown = qs.parse(query)
  return cache as QueryState
}

function calculatedGroupingIsDisabled<T>(isLoading: boolean, data: Array<T> | undefined): boolean {
  if (isLoading) {
    return true
  }

  return !data || !data.length
}

function calculateIsAllDataSelected<T>(data: Array<T> | undefined, selected: Array<ID>): boolean {
  if (!data) {
    return false
  }

  return data.length > 0 && data.length === selected.length
}

function groupingOnSelect(
  id: ID,
  selected: Array<ID>,
  setSelected: Dispatch<SetStateAction<Array<ID>>>
) {
  if (!id) {
    return
  }

  if (selected.includes(id)) {
    setSelected(selected.filter((itemId) => itemId !== id))
  } else {
    const updatedSelected = [...selected]
    updatedSelected.push(id)
    setSelected(updatedSelected)
  }
}

function groupingOnSelectAll<T>(
  isAllSelected: boolean,
  setSelected: Dispatch<SetStateAction<Array<ID>>>,
  data?: Array<T & { id?: ID }>
) {
  if (isAllSelected) {
    setSelected([])
    return
  }

  if (!data || !data.length) {
    return
  }

  setSelected(data.filter((item) => item.id).map((item) => item.id))
}

// Hook
function useDebounce(value: string | undefined, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )
  return debouncedValue
}

function createToastMessage(detail: any) {
  let toastMessage = 'Có lỗi xảy ra:\n'
  for (const key in detail) {
    toastMessage += `- ${detail[key]}\n`
  }
  return toastMessage
}

function handleError(
  error: any,
  functionSetErrors?: any,
  defaultData?: any,
  showMessageErrors: boolean = false
) {
  const errorDetail = error?.response?.data?.detail
  const errorCode = error?.response?.data?.errorCode
  const errorMessage = error?.response?.data?.message
  const errorStatus = error?.response?.status
  console.log(errorMessage)
  if (errorStatus == 401) {
    ToastMessage('error', 'Phiên đăng nhập hết hạn')
  } else if (
    errorDetail &&
    !errorDetail?.exception_message &&
    Object.keys(errorDetail).length > 0 && !showMessageErrors
  ) {
    const apiErrors = errorDetail
    if (defaultData && functionSetErrors) {
      Object.keys(defaultData).forEach((field) => {
        if (apiErrors[field]) {
          functionSetErrors(field, apiErrors[field])
        }
      })
    }
    return
  } else if (errorCode && errorCodeVariables[errorCode]) {
    ToastMessage('error', errorCodeVariables[errorCode])
  } else if (errorMessage) {
    ToastMessage('error', errorMessage)
  } else {
    ToastMessage('error', null)
  }
}

function containsVietnameseCharacters(str: any) {
  const regex = /[^\x00-\x7F]/
  return regex.test(str)
}

function containsOnlyNumbers(str: any) {
  const regex = /^[0-9]+$/
  return regex.test(str)
}

function renderCanhBaoChiPhi(value: Number) {
  switch (value) {
    case 3:
      return (
        <div>
          <img src={'/media/common/warning.svg'} style={{ marginRight: '5px' }} />
          <span style={{ color: '#FC6B03' }}>HS từ chối 1 phần</span>
        </div>
      )
    case 4:
      return (
        <div>
          <img src={'/media/common/warning.svg'} style={{ marginRight: '5px' }} />
          <span style={{ color: '#FC6B03' }}>HS từ chối toàn bộ</span>
        </div>
      )
    case 1:
      return (
        <div>
          <img src={'/media/common/success.svg'} style={{ marginRight: '5px' }} />
          <span style={{ color: '#39AC6D' }}>HS thanh toán toàn bộ</span>
        </div>
      )
    case 2:
      return (
        <div>
          <img src={'/media/common/danger.svg'} style={{ marginRight: '5px' }} />
          <span style={{ color: '#E14337' }}>HS cảnh báo</span>
        </div>
      )
  }
}

function renderRowTrangThai(
  backgroundDiv: any,
  colorSpan: any,
  colorDots: any,
  title: any,
  border: any = false,
  isTrangThai?: any
) {
  return (
    <div
      className='p-2'
      style={{
        display: 'flex',
        backgroundColor: backgroundDiv,
        borderRadius: 15,
        border: border ? '1px solid #366AE2' : '',
        alignItems: 'center',
        justifyContent: isTrangThai === 'phieuThanhToan' ? 'left' : 'center',
      }}
    >
      <i
        className='fa-solid fa-circle'
        style={{ paddingRight: 5, color: colorDots, fontSize: 8 }}
      ></i>
      <span style={{ color: colorSpan }}>{title}</span>
    </div>
  )
}

function renderRowTrangThaiNotDotted(
  backgroundDiv: any,
  colorSpan: any,
  title: any,
  border: any = false,
  isTrangThai?: any
) {
  return (
    <div
      className='p-2'
      style={{
        display: 'flex',
        backgroundColor: backgroundDiv,
        borderRadius: 8,
        border: border ? '1px solid #366AE2' : '',
        alignItems: 'center',
        justifyContent: isTrangThai === 'phieuThanhToan' ? 'left' : 'center',
      }}
    >
      <span style={{ color: colorSpan }}>{title}</span>
    </div>
  )
}

const loadingTemplate = <T, K extends keyof T>(
  rowData: T,
  columnKey: K,
  loading: boolean,
  customRender?: (data: T) => ReactNode
) => {
  return loading ? (
    <div
      className='flex align-items-center'
      style={{ height: '17px', flexGrow: '1', overflow: 'hidden' }}
    >
      <Skeleton width={'80%'} height='1rem' />
    </div>
  ) : customRender ? (
    customRender(rowData)
  ) : (
    <span>{rowData[columnKey] as ReactNode}</span>
  )
}
function renderTrangThaiHoSo(value: number) {
  switch (value) {
    case 0:
      return renderRowTrangThai('#F2F5F8', '#00204D', '#00204D', 'Đang thẩm định tự động')
    case 1:
      return renderRowTrangThai('#FFFFFF', '#366AE2', '#366AE2', 'Đã thẩm định tự động', true)
    case 2:
      return renderRowTrangThai('#F6EEFC', '#943CDD', '#943CDD', 'Đã thẩm định chủ động')
    case 3:
      return renderRowTrangThai('#FFF3EB', '#FC6B03', '#FC6B03', 'Đã đề nghị duyệt')
    case 4:
      return renderRowTrangThai('#E8F7EF', '#39AC6D', '#39AC6D', 'Đã duyệt')
    case 5:
      return renderRowTrangThai('#FCEEED', '#E14337', '#E14337', 'Bị trả lại')
    case 6:
      return renderRowTrangThai('#FBF5E4', '#C8981E', '#C8981E', 'Đang thẩm định chủ động')
    case 7:
      return renderRowTrangThai('#FCEEED', '#E14337', '#E14337', 'Hủy duyệt')
    case 8:
      return renderRowTrangThai('#FCEEED', '#366AE2', '#366AE2', 'Đang đề nghị thanh toán')
    case 9:
      return renderRowTrangThai('#FCEEED', '#366AE2', '#366AE2', 'Đã thanh toán')
    case 10:
      return renderRowTrangThai('#FCEEED', '#E14337', '#E14337', 'Từ chối thanh toán')
  }
}
function renderTrangThaiHSTTTT(value: number) {
  switch (value) {
    case 1:
      return renderRowTrangThai('#FFFFFF', '#366AE2', '#366AE2', 'Khởi tạo')
    case 2:
      return renderRowTrangThai('#F2F5F8', '#00204D', '#00204D', 'Đang thẩm định tự động')
    case 3:
      return renderRowTrangThai('#FFFFFF', '#366AE2', '#366AE2', 'Đã thẩm định tự động')
    case 4:
      return renderRowTrangThai('#FBF5E4', '#C8981E', '#C8981E', 'Đang thẩm định chủ động')
    case 5:
      return renderRowTrangThai('#F6EEFC', '#943CDD', '#943CDD', 'Đã thẩm định chủ động')
    case 6:
      return renderRowTrangThai('#FFF3EB', '#FC6B03', '#FC6B03', 'Đã đề nghị duyệt')
    case 7:
      return renderRowTrangThai('#FCEEED', '#E14337', '#E14337', 'Bị trả lại')
    case 8:
      return renderRowTrangThai('#E8F7EF', '#39AC6D', '#39AC6D', 'Đã duyệt')
    case 9:
      return renderRowTrangThai('#FCEEED', '#E14337', '#E14337', 'Huỷ duyệt')
    case 10:
      return renderRowTrangThai('#FFFFFF', '#366AE2', '#366AE2', 'Đang đề nghị thanh toán')
    case 11:
      return renderRowTrangThai('#FFFFFF', '#366AE2', '#366AE2', 'Đã thanh toán')
    case 12:
      return renderRowTrangThai('#FFFFFF', '#366AE2', '#366AE2', 'Từ chối thanh toán')
    case 13:
      return renderRowTrangThai('#FFFFFF', '#366AE2', '#366AE2', 'Từ chối bồi thường')
    case 14:
      return renderRowTrangThai('#FFFFFF', '#366AE2', '#366AE2', 'Chờ bổ sung chứng từ')
    case 15:
      return renderRowTrangThai('#FFFFFF', '#366AE2', '#366AE2', 'Đã bổ sung chứng từ')
  }
}
function renderTienTrinhHoSoHSTTTT(value: number) {
  switch (value) {
    case 1:
      return renderRowTrangThai('#FFFFFF', '#366AE2', '#366AE2', 'Khởi tạo')
    case 2:
      return renderRowTrangThai('#FFFFFF', '#366AE2', '#366AE2', 'Đang xử lý')
    case 3:
      return renderRowTrangThai('#FCEEED', '#E14337', '#E14337', 'Từ chối bồi thường')
    case 4:
      return renderRowTrangThai('#FFF3EB', '#FC6B03', '#FC6B03', 'Chờ duyệt phân cấp')
    case 5:
      return renderRowTrangThai('#E8F7EF', '#39AC6D', '#39AC6D', 'Đã duyệt phân cấp')
    case 6:
      return renderRowTrangThai('#E8F7EF', '#39AC6D', '#39AC6D', 'Từ chối duyệt phân cấp')
    case 7:
      return renderRowTrangThai('#E8F7EF', '#39AC6D', '#39AC6D', 'Đã duyệt')
    case 8:
      return renderRowTrangThai('#FFFFFF', '#366AE2', '#366AE2', 'Đang đề nghị thanh toán')
    case 9:
      return renderRowTrangThai('#FFFFFF', '#366AE2', '#366AE2', 'Đã thanh toán')
  }
}

function renderTrangThaiHoSoTienTrinh(value: number) {
  switch (value) {
    case 1:
      return renderRowTrangThai('#FFFFFF', '#366AE2', '#366AE2', 'Đã gửi yêu cầu')
    case 2:
      return renderRowTrangThai('#F2F5F8', '#00204D', '#00204D', 'Đang xử lý')
    case 3:
      return renderRowTrangThai('#FBF5E4', '#C8981E', '#C8981E', 'Đã thẩm định')
    case 4:
      return renderRowTrangThai('#FCEEED', '#E14337', '#E14337', 'Từ chối bồi thường')
    case 5:
      return renderRowTrangThai('#F6EEFC', '#943CDD', '#943CDD', 'Yêu cầu bổ sung chứng từ')
    case 6:
      return renderRowTrangThai('#FFF3EB', '#FC6B03', '#FC6B03', 'Đã bổ sung chứng từ')
    case 7:
      return renderRowTrangThai('#FCEEED', '#E14337', '#E14337', 'Yêu cầu thanh toán')
    case 8:
      return renderRowTrangThai('#E8F7EF', '#39AC6D', '#39AC6D', 'Đã thanh toán')
  }
}


function renderTrangThaiDTBL(value: number) {
  switch (value) {
    case 0:
      return renderRowTrangThai('#FFFFFF', '#366AE2', '#366AE2', 'Chưa duyệt')
    case 1:
      return renderRowTrangThai('#E8F7EF', '#39AC6D', '#39AC6D', 'Đã duyệt')
    case 2:
      return renderRowTrangThai('#FCEEED', '#E14337', '#E14337', 'Từ chối')
    case 6:
      return renderRowTrangThai('#F6EEFC', '#943CDD', '#943CDD', 'Chờ bổ sung chứng từ')
    case 7:
      return renderRowTrangThai('#F6EEFC', '#943CDD', '#943CDD', 'Đã bổ sung chứng từ')
  }
}

function renderTrangThaiNguoiDung(value: boolean) {
  if (value === true) {
    return renderRowTrangThai('#FFFFFF', '#366AE2', '#366AE2', 'Đã kích hoạt')
  } else {
    return renderRowTrangThai('#FCEEED', '#E14337', '#E14337', 'Bị khóa')
  }
}

function renderRowTrangThai_chitiethskcb(
  backgroundDiv: any,
  colorSpan: any,
  colorDots: any,
  title: any,
  border: any = false,
  cbcp: any = false
) {
  return (
    <div
      className='p-2'
      style={{
        backgroundColor: backgroundDiv,
        borderRadius: 15,
        border: border && cbcp ? `1px solid ${cbcp}` : border ? '1px solid #366AE2' : '',
      }}
    >
      {/* <i
          className='fa-solid fa-circle'
          style={{ paddingRight: 5, color: colorDots, fontSize: 8 }}
        ></i> */}
      <span style={{ color: colorSpan }}>{title}</span>
    </div>
  )
}

function renderTrangThaiThanhToanDanhSach(value: number) {
  switch (value) {
    case 1:
      return ''
    case 0:
      return renderRowTrangThai(
        '#FFF3EB',
        '#366AE2',
        '#366AE2',
        'Chờ phê duyệt',
        false,
        'phieuThanhToan'
      )
    case 2:
      return renderRowTrangThai(
        '#FFF3EB',
        '#C8981E',
        '#C8981E',
        'Chưa thực hiện',
        false,
        'phieuThanhToan'
      )

    case 3:
      return renderRowTrangThai(
        '#E8F7EF',
        '#39AC6D',
        '#39AC6D',
        'Hoàn thành',
        false,
        'phieuThanhToan'
      )
    case 4:
      return renderRowTrangThai(
        '#FCEEED',
        '#E14337',
        '#E14337',
        'Không thực hiện',
        false,
        'phieuThanhToan'
      )
    case 5:
      return renderRowTrangThai(
        '#FCEEED',
        '#FC6B03',
        '#FC6B03',
        'Không hợp lệ',
        false,
        'phieuThanhToan'
      )
  }
}

function renderTrangThaiHoanQuy(value: number) {
  switch (value) {
    case 1:
      return renderRowTrangThai(
        '#e5eaef',
        '#667185',
        '#667185',
        'Chưa thực hiện',
        false,
        'phieuThanhToan'
      )
    case 2:
      return renderRowTrangThai(
        '#fdf5e6',
        '#e18309',
        '#e18309',
        'Đã hoàn quỹ một phần',
        false,
        'phieuThanhToan'
      )
    case 3:
      return renderRowTrangThai(
        '#E8F7EF',
        '#39AC6D',
        '#39AC6D',
        'Hoàn thành',
        false,
        'phieuThanhToan'
      )
  }
}

function renderTrangThaiThanhToan(value: number) {
  switch (value) {
    case 2:
      return renderRowTrangThai_chitiethskcb('#F6EEFC', '#943CDD', '#943CDD', 'Đã duyệt')
    case 3:
      return renderRowTrangThai_chitiethskcb(
        '#FFF3EB',
        '#FC6B03',
        '#FC6B03',
        'Đang đề nghị thanh toán'
      )
    case 4:
      return renderRowTrangThai_chitiethskcb('#E8F7EF', '#39AC6D', '#39AC6D', 'Đã thanh toán')
    case 5:
      return renderRowTrangThai_chitiethskcb('#E8F7EF', '#E14337', '#E14337', 'Từ chối thanh toán')
    // case 6:
    //   return renderRowTrangThai_chitiethskcb('#E8F7EF', '#C8981E', '#C8981E', 'Chưa thực hiện')
  }
}

function renderTrangThaiHoSo_chitiethskcb(value: number) {
  switch (value) {
    case 0:
      return renderRowTrangThai_chitiethskcb(
        '#F2F5F8',
        '#00204D',
        '#00204D',
        'Đang thẩm định tự động'
      )
    case 1:
      return renderRowTrangThai_chitiethskcb(
        '#EDF2FD',
        '#366AE2',
        '#366AE2',
        'Đã thẩm định tự động',
        true
      )
    case 2:
      return renderRowTrangThai_chitiethskcb(
        '#F6EEFC',
        '#943CDD',
        '#943CDD',
        'Đã thẩm định chủ động'
      )
    case 3:
      return renderRowTrangThai_chitiethskcb('#FFF3EB', '#FC6B03', '#FC6B03', 'Đã đề nghị duyệt')
    case 4:
      return renderRowTrangThai_chitiethskcb('#E8F7EF', '#39AC6D', '#39AC6D', 'Đã duyệt')
    case 5:
      return renderRowTrangThai_chitiethskcb('#FCEEED', '#E14337', '#E14337', 'Bị trả lại')
    case 7:
      return renderRowTrangThai_chitiethskcb('#FCEEED', '#E14337', '#E14337', 'Hủy duyệt')
    case 8:
      return renderRowTrangThai_chitiethskcb(
        '#FCEEED',
        '#366AE2',
        '#366AE2',
        'Đang đề nghị thanh toán'
      )
    case 9:
      return renderRowTrangThai_chitiethskcb('#FCEEED', '#366AE2', '#366AE2', 'Đã thanh toán')
    case 10:
      return renderRowTrangThai_chitiethskcb('#FCEEED', '#E14337', '#E14337', 'Từ chối thanh toán')
  }
}

function renderTrangThaiHoSo_canhBaoChiPhi(value: number) {
  switch (value) {
    case 3:
    case 2:
    case 1:
      return (
        <button type='button' className='btn btn-sm bg-warning text-white'>
          <span>Cảnh báo chi phí</span>
        </button>
      )
    // renderRowTrangThai_chitiethskcb(
    //   '#FBF5E4', '#C8981E', '#C8981E',
    //   'Từ chối toàn bộ',true,'#C8981E',
    // )
    // case 2:
    //   return  <button className='btn btn-sm bg-warning text-white'> <span >Từ chối một phần</span></button>
    // renderRowTrangThai_chitiethskcb('#FBF5E4', '#C8981E', '#C8981E', 'Từ chối một phần',true, '#C8981E')
    // case 1:
    //   return  <button className='btn btn-sm bg-warning text-white'> <span >Cảnh báo</span></button>
    // return renderRowTrangThai_chitiethskcb('#FBF5E4', '#C8981E', '#C8981E', 'Cảnh báo',true, '#C8981E')
    default:
      return null
  }
}

function renderGioiTinh(value: number) {
  switch (value) {
    case 1:
      return (
        <div>
          <span>Nam</span>
        </div>
      )
    case 2:
      return (
        <div>
          <span>Nữ</span>
        </div>
      )
    case 3:
      return (
        <div>
          <span>Khác</span>
        </div>
      )
  }
}

function renderGender(value: number) {
  switch (value) {
    case 1:
      return (
        <div>
          <span>Nam</span>
        </div>
      )
    case 2:
      return (
        <div>
          <span>Nữ</span>
        </div>
      )
    case 3:
      return (
        <div>
          <span>Khác</span>
        </div>
      )
  }
}

function reverseNgaySinh(inputTimeString: any) {
  const year = inputTimeString?.toString().slice(0, 4)
  const month = inputTimeString?.toString().slice(4, 6)
  const day = inputTimeString?.toString().slice(6, 8)
  const formattedDateTime = `${day}-${month}-${year}`
  return formattedDateTime
}

function reverseNumber(inputTimeString: any) {
  if (inputTimeString) {
    const year = inputTimeString?.toString().slice(0, 4)
    const month = inputTimeString?.toString().slice(4, 6)
    const day = inputTimeString?.toString().slice(6, 8)
    const hour = inputTimeString?.toString().slice(8, 10)
    const minute = inputTimeString?.toString().slice(10, 12)
    const second = inputTimeString?.toString().slice(12, 14)

    const date = new Date(`${year}-${month}-${day}T${hour}:${minute}:00`)
    const formattedDateTime = `${day}-${month}-${year} ${hour}:${minute}:${second || '00'}`
    return formattedDateTime
  } else return ''
}

function reverseNumberNotSeconds(inputTimeString: any) {
  if (inputTimeString) {
    const year = inputTimeString?.toString().slice(0, 4)
    const month = inputTimeString?.toString().slice(4, 6)
    const day = inputTimeString?.toString().slice(6, 8)
    const hour = inputTimeString?.toString().slice(8, 10)
    const minute = inputTimeString?.toString().slice(10, 12)
    const second = inputTimeString?.toString().slice(12, 14)

    const date = new Date(`${year}-${month}-${day}T${hour}:${minute}:00`)
    const formattedDateTime = `${day}-${month}-${year} ${hour}:${minute}`
    return formattedDateTime
  } else return ''
}

function formatDateTime(inputTimeString: number | null, format: string) {
  if (inputTimeString === null) {
    return ''
  }
  const inputString = inputTimeString.toString()

  const YYYY = inputString.slice(0, 4)
  const MM = inputString.slice(4, 6)
  const DD = inputString.slice(6, 8)
  const hh = inputString.slice(8, 10)
  const mm = inputString.slice(10, 12)
  const ss = inputString.slice(12, 14)

  let formattedDateTime = format
    .replace('YYYY', YYYY)
    .replace('MM', MM)
    .replace('DD', DD)
    .replace('hh', hh)
    .replace('mm', mm)
    .replace('ss', ss)

  return formattedDateTime
}

function formatStringToMilisecond(inputTimeString: any) {
  var inputTimeStringConvert = inputTimeString.toString()
  var year = inputTimeStringConvert.substring(0, 4)
  var month = inputTimeStringConvert.substring(4, 6) - 1 // Trừ 1 vì tháng trong JavaScript bắt đầu từ 0
  var day = inputTimeStringConvert.substring(6, 8)
  var date = new Date(year, month, day)

  // Lấy số millisecond từ Epoch (1/1/1970) đến thời điểm đã chọn
  return date.getTime()
}

function convertKyQT(value: any) {
  if (!value) {
    return null
  }
  let month = String(value).substring(4, 6)
  let year = String(value).substring(0, 4)
  return `${month}/${year}`
}

const paginationOptions = {
  rowsPerPageText: 'Dòng hiển thị',
  rangeSeparatorText: 'trên',
}

function formatCurrency(number: any) {
  return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

function formatCurrencyComma(number: any) {
  return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const renderLyDoVaoVien = (value: number) => {
  switch (value) {
    case 1:
      return (
        <div>
          <span>Đúng tuyến</span>
        </div>
      )
    case 2:
      return (
        <div>
          <span>Cấp cứu</span>
        </div>
      )
    case 3:
      return (
        <div>
          <span>Trái tuyến</span>
        </div>
      )
    case 4:
      return (
        <div>
          <span>Thông tuyến</span>
        </div>
      )
  }
}

const renderKQDT = (value: number) => {
  switch (value) {
    case 1:
      return (
        <div>
          <span>Khỏi</span>
        </div>
      )
    case 2:
      return (
        <div>
          <span>Đỡ</span>
        </div>
      )
    case 3:
      return (
        <div>
          <span>Không thay đổi</span>
        </div>
      )
    case 4:
      return (
        <div>
          <span>Nặng hơn</span>
        </div>
      )
    case 5:
      return (
        <div>
          <span>Tử vong</span>
        </div>
      )
  }
}

const renderTTRV = (value: number) => {
  switch (value) {
    case 1:
      return (
        <div>
          <span>Ra viện</span>
        </div>
      )
    case 2:
      return (
        <div>
          <span>Chuyển viện</span>
        </div>
      )
    case 3:
      return (
        <div>
          <span>Trốn viện</span>
        </div>
      )
    case 4:
      return (
        <div>
          <span>Xin ra viện</span>
        </div>
      )
  }
}

const formattedNumberToVND = (data: number) => {
  if (data !== null) {
    return `${data?.toLocaleString('vi-VN', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })} ₫`
  } else {
    return null
  }
}

const formattedNumber = (data: number) => {
  if (data !== null) {
    return `${data?.toLocaleString('vi-VN', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })} `
  } else {
    return null
  }
}

const formattedNumberDot = (data: number) => {
  if (data !== null) {
    return `${data?.toLocaleString('vi-VN', {
      style: 'decimal',
      // minimumFractionDigits: 2,
      // maximumFractionDigits: 2,
    })}`
  } else {
    return null
  }
}

function removeNullFields(obj: any) {
  const filteredObj: any = {}
  for (const key in obj) {
    if (
      obj[key] !== null &&
      obj[key] !== 'null' &&
      obj[key] !== '' &&
      obj[key] !== 'all' &&
      obj[key] !== undefined
    ) {
      filteredObj[key] = obj[key]
    }
  }
  return filteredObj
}

function ChangeText(str: string) {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
  str = str.replace(/đ/g, 'd')
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A')
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E')
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I')
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O')
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U')
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y')
  str = str.replace(/Đ/g, 'D')
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '') // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, '') // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, ' ')
  str = str.trim()
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  str = str.replace(
    /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
    ' '
  )
  return str
}

function renderTrangThaiHoSoTTTTValue(value: number) {
  switch (value) {
    case 1:
      return 'Khởi tạo'
    case 2:
      return 'Đang thẩm định tự động'
    case 3:
      return 'Đã thẩm định tự động'
    case 4:
      return 'Đang thẩm định chủ động'
    case 5:
      return 'Đã thẩm định chủ động'
    case 6:
      return 'Đã đề nghị duyệt'
    case 7:
      return 'Bị trả lại'
    case 8:
      return 'Đã duyệt'
    case 9:
      return 'Huỷ duyệt'
  }
}

function renderLoaiHoSoThanhToan(value: number) {
  switch (value) {
    case 1:
      return 'XML'
    case 2:
      return 'Hồ sơ hoàn trả'
    case 3:
      return 'Hồ sơ mobile app'
  }
}

function renderDoiTuongThanhToan(value: number) {
  switch (value) {
    case 1:
      return 'Khách lẻ'
    case 2:
      return 'CSKCB'
    case 3:
      return 'TPA'
  }
}

function updatePaginationInfo(page: number, perPage: number, totalRecords: number, listData: any) {
  const startRecord = page * perPage + 1
  const endRecord = (page + 1) * perPage
  return listData?.length > 0 ? (
    <>
      <i className='fa-solid fa-circle' style={{ color: 'black', fontSize: 8, paddingRight: 5 }}></i>
      <span>
        {`Hiển thị ${startRecord}-${totalRecords < endRecord ? totalRecords : endRecord
          } trên tổng số ${totalRecords} bản ghi`}{' '}
      </span>
    </>
  ) : (
    <></>
  )
}
const loaiKenhKhaiThac = [
  {
    label: 'Tất cả',
    value: null,
  },
  {
    label: 'Khai thác trực tiếp',
    value: '1',
  },
  {
    label: 'Khai thác qua đại lý',
    value: '2',
  },
  {
    label: 'Môi giới',
    value: '3',
  },
]
const phuongThucBoiThuongDanhSach = [
  {
    label: 'Tất cả',
    value: null,
  },
  {
    label: 'Trực tiếp',
    value: '1',
  },
  {
    label: 'Bên thứ ba',
    value: '2',
  },
]
const renderLoaiKenhKhaiThac = (value: any) => {
  const result = loaiKenhKhaiThac.find((e: any) => e?.value === String(value))
  return result?.label
}
const renderLoaiPhuongThucBoiThuong = (value: any) => {
  const result = phuongThucBoiThuongDanhSach.find((e: any) => e?.value === String(value))
  return result?.label
}
const CurrencyDisplay = ({ amount }: any) => {
  // Sử dụng Intl.NumberFormat để định dạng số theo định dạng tiền tệ với ký hiệu đồng
  const formattedAmount = new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND', // Mã tiền tệ cho đồng Việt Nam
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount)

  return (
    <div>
      {/* Hiển thị số đã định dạng */}
      {formattedAmount}
    </div>
  )
}
const CurrencyDisplayHopDong = (amount: any) => {
  // Sử dụng Intl.NumberFormat để định dạng số theo định dạng tiền tệ với ký hiệu đồng
  const formattedAmount = new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND', // Mã tiền tệ cho đồng Việt Nam
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount)

  return (
    <div>
      {/* Hiển thị số đã định dạng */}
      {formattedAmount}
    </div>
  )
}

const CurrencyDisplayHopDongNguoiDuocBH = (data: number) => {
  if (data) {
    return data.toLocaleString('vi-VN', {
      style: 'currency',
      currency: 'VND',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  } else {
    return null
  }
}

const apiDownloadFileCustom = (
  api: String,
  type: String,
  fileName: any,
  setIsLoading: Function,
  objectFilter: any
) => {
  console.log(objectFilter)
  setIsLoading(true)
  for (const key in objectFilter) {
    if (
      objectFilter[key] === null ||
      objectFilter[key] === 'null' ||
      objectFilter[key] === '' ||
      objectFilter[key]?.length == 0
    ) {
      delete objectFilter[key]
    }
  }
  const objString = '?' + new URLSearchParams({ ...objectFilter, type }).toString()
  axios
    .get(`${api}${objString}`, {
      responseType: 'arraybuffer', // Ensure binary response
    })
    .then(({ data }) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const url = URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = url
      link.download = `${fileName}.${type}`
      document.body.appendChild(link)
      link.click()

      // Clean up after the download
      URL.revokeObjectURL(url)
      document.body.removeChild(link)
    })
    .catch((err) => {
      ToastMessage('error', 'Tải file thất bại')
    })
    .finally(() => {
      setIsLoading(false)
    })
}
const btnExport = (isloading: boolean, exportFunction: any, className: string, title: string) => {
  const renderExport = () => {
    return isloading ? (
      <button className={className} onClick={exportFunction}>
        {title}
      </button>
    ) : (
      <button className={className}>
        <LoadingIndicator />
      </button>
    )
  }
  return renderExport()
}

const RenderLimitText = ({ row, field }: { row: any; field: any }) => {
  const renderLimit = () => {
    return (
      <div>
        <p className='limit-span' data-tip={row[field]}>
          {row[field]}
        </p>
        <ReactTooltip place='bottom' type='dark' effect='solid' />
      </div>
    )
  }

  return renderLimit()
}

function convertToTimestamp(date: Date | null) {
  return date instanceof Date ? date.getTime() : null
}

const getNgayThoiGianCuoiNgay = (date: any, key: any) => {
  if (key === 'tuNgayDefault') {
    return new Date(new Date().setMonth(new Date().getMonth() - 3)).setHours(0, 0, 0, 0)
  } else if (key === 'denNgayDefault') {
    return (
      new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).getTime() +
      24 * 3600 * 1000 -
      1
    )
  } else if (key === 'denNgayValue') {
    return new Date(date).getTime() + 24 * 3600 * 1000 - 1
  }
}

const listDoiTuongThanhToan = [
  { label: 'Khách lẻ', value: 1 },
  { label: 'CSKCB', value: 2 },
  { label: 'TPA', value: 3 },
]

const listTinhTrangHS = [
  { label: 'Đang thẩm định tự động', value: 0 },
  { label: 'Xong tự động', value: 1 },
  { label: 'Đã thẩm định chủ động', value: 2 },
  { label: 'Đã đề nghị duyệt', value: 3 },
  { label: 'Đã duyệt', value: 4 },
  { label: 'Từ chối duyệt', value: 5 },
  { label: 'Đang thẩm định chủ động', value: 6 },
  { label: 'Hủy duyệt', value: 7 },
  { label: 'Đang đề nghị thanh toán', value: 8 },
  { label: 'Đã thanh toán', value: 9 },
  { label: 'Từ chối thanh toán', value: 10 },
]

const listLoaiHoSo = [
  { label: 'Tất cả', value: null },
  { label: 'Hồ sơ hoàn trả', value: '[2,3]' },
  {
    label: 'Hồ sơ bảo lãnh nội trú',
    value: '4_[3, 4, 8, 9, 11, 12, 13, 14, 15, 16, 17]',
    dm_ma_loai_kcb: [3, 4, 8, 9, 11, 12, 13, 14, 15, 16, 17],
  },
  {
    label: 'Hồ sơ bảo lãnh ngoại trú',
    value: '4_[7, 10, 2, 1, 6, 5]',
    dm_ma_loai_kcb: [7, 10, 2, 1, 6, 5],
  },
]

const listTrangThaiThanhToan = [
  { label: 'Tất cả', value: null },
  // {label: 'Chờ thanh toán', value: 1},
  // {label: 'Đã duyệt', value: 2},
  // {label: 'Đã đề nghị thanh toán', value: 3},
  // {label: 'Đã thanh toán', value: 4},
  // {label: 'Đã từ chối', value: 5},
  // updateddđ
  // {label: 'Chờ duyệt', value: 1},
  { label: 'Chờ phê duyệt', value: 0 },
  { label: 'Chưa thực hiện', value: 2 },
  { label: 'Hoàn thành', value: 3 },
  { label: 'Không thực hiện', value: 4 },
  { label: 'Không hợp lệ', value: 5 },
]

const listTrangThaiPhieu = [
  { label: 'Tất cả', value: null },
  { label: 'Chờ duyệt', value: 1 },
  { label: 'Đã duyệt', value: 2 },
  { label: 'Bị trả lại', value: 3 },
  { label: 'Hủy duyệt', value: 4 },
  { label: 'Cán bộ quản lý ngân sách đã duyệt', value: 5 },
]
const trangThaiPheDuyet = [
  { label: 'Chờ duyệt', value: 1 },
  { label: 'Đã duyệt', value: 2 },
  { label: 'Bị trả lại', value: 3 },
  { label: 'Hủy duyệt', value: 4 },
]
const trangThaiThanhToan = [
  // {label: 'Chờ duyệt', value: 1},
  { label: 'Chờ phê duyệt', value: 0 },
  { label: 'Chưa thực hiện', value: 2 },
  { label: 'Hoàn thành', value: 3 },
  { label: 'Không thực hiện', value: 4 },
  { label: 'Không hợp lệ', value: 5 },
]
const listDoiTuongThanhToanNew = [
  { label: 'Bệnh nhân', value: 1 },
  { label: 'Bệnh viện', value: 2 },
]
function renderTrangThaiPhieuThanhToan(data: any) {
  switch (data) {
    case 1:
      return (
        <div
          className='p-2'
          style={{
            backgroundColor: '#FFF3EB',
            borderRadius: 15,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <i
            className='fa-solid fa-circle'
            style={{ paddingRight: 5, color: 'lime', fontSize: 8 }}
          ></i>
          <span style={{ color: 'lime' }}>Tạo mới</span>
        </div>
      )

    case 2:
      return (
        <div
          className='p-2'
          style={{
            backgroundColor: '#FFFAF0',
            borderRadius: 15,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <i
            className='fa-solid fa-circle'
            style={{ paddingRight: 5, color: '#FF0000', fontSize: 8 }}
          ></i>
          <span style={{ color: '#FF0000' }}>Phiếu bị hủy</span>
        </div>
      )
    case 3:
      return (
        <div
          className='p-2'
          style={{
            backgroundColor: '#FFF3EB',
            borderRadius: 15,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <i
            className='fa-solid fa-circle'
            style={{ paddingRight: 5, color: '#FC6B03', fontSize: 8 }}
          ></i>
          <span style={{ color: '#FC6B03' }}>Chờ lãnh đạo kế toán duyệt</span>
        </div>
      )
    case 4:
      return (
        <div
          className='p-2'
          style={{
            backgroundColor: '#FFFAF0',
            borderRadius: 15,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <i
            className='fa-solid fa-circle'
            style={{ paddingRight: 5, color: 'lime', fontSize: 8 }}
          ></i>
          <span style={{ color: 'lime' }}>Chờ lãnh đạo công ty duyệt chi</span>
        </div>
      )
    case 5:
      return (
        <div
          className='p-2'
          style={{
            backgroundColor: '#FFFAF0',
            borderRadius: 15,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <i
            className='fa-solid fa-circle'
            style={{ paddingRight: 5, color: '#7B68EE', fontSize: 8 }}
          ></i>
          <span style={{ color: '#7B68EE' }}>Đã duyệt chi</span>
        </div>
      )
    case 6:
      return (
        <div
          className='p-2'
          style={{
            backgroundColor: '#FFFAF0',
            borderRadius: 15,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <i
            className='fa-solid fa-circle'
            style={{ paddingRight: 5, color: '#FF0000', fontSize: 8 }}
          ></i>
          <span style={{ color: '#FF0000' }}>Phiếu cần kiểm tra/bổ sung chứng từ</span>
        </div>
      )
    default:
      return (
        <div
          className='p-2'
          style={{
            backgroundColor: '#FFF3EB',
            borderRadius: 15,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <i
            className='fa-solid fa-circle'
            style={{ paddingRight: 5, color: '#FC6B03', fontSize: 8 }}
          ></i>
          <span style={{ color: '#FC6B03' }}>Chưa xác định</span>
        </div>
      )
  }
}

const renderLoaiKcb = (data: any) => {
  switch (data) {
    case 1:
      return <div>Nội trú</div>
    case 2:
      return <div>Ngoại trú</div>
    case 3:
      return <div>Tai nạn</div>
    case 4:
      return <div>Thai sản sinh thường</div>
    case 5:
      return <div>Thai sản sinh mổ</div>
    case 6:
      return <div>Biến chứng thai sản</div>
  }
}
function renderTrangThaiPhieu(value: number) {
  switch (value) {
    case 1:
      return renderRowTrangThai(
        '#E8F7EF',
        '#39AC6D',
        '#39AC6D',
        'Chờ duyệt',
        false,
        'phieuThanhToan'
      )

    case 2:
      return renderRowTrangThai(
        '#FFF3EB',
        '#FC6B03',
        '#FC6B03',
        'Đã duyệt',
        false,
        'phieuThanhToan'
      )

    case 3:
      return renderRowTrangThai(
        '#FCEEED',
        '#E14337',
        '#E14337',
        'Bị trả lại',
        false,
        'phieuThanhToan'
      )
    case 4:
      return renderRowTrangThai('#FCEEED', '#C8981E', '#C8981E', 'Đã hủy', false, 'phieuThanhToan')
    case 5:
      return renderRowTrangThai(
        '#F6EEFC',
        '#943CDD',
        '#943CDD',
        'Cán bộ quản lý ngân sách đã duyệt',
        false,
        'phieuThanhToan'
      )
  }
}

function formatNumberNghinDongChart(number: any) {
  const roundedNumber = Math.round(number / 1000);
  return roundedNumber.toLocaleString(); // Định dạng số với dấu phân cách ngàn
}

const parseFormattedNumberCopy = (value: any) => {
  // Kiểm tra nếu số có nhiều hơn một dấu chấm hoặc phẩy và chúng đều cùng là dấu chấm hoặc dấu phẩy
  let dotCount = (value.match(/\./g) || []).length
  let commaCount = (value.match(/,/g) || []).length
  if ((dotCount > 1 && commaCount == 0) || (commaCount > 1 && dotCount == 0)) {
    value = value.replace(/[.,]/g, '')
    let cleanedValue = value.replace(/[^\d]/g, '')
    let parsedNumber = parseFloat(cleanedValue)
    return isNaN(parsedNumber) ? '' : parsedNumber
  } else {
    // Tìm và giữ lại dấu phân cách thập phân cuối cùng (dấu chấm hoặc dấu phẩy)
    let lastDecimalSeparatorIndex = Math.max(value.lastIndexOf('.'), value.lastIndexOf(','))
    if (lastDecimalSeparatorIndex !== -1) {
      // Loại bỏ các dấu phân cách thập phân khác trước dấu phân cách cuối cùng
      let cleanedValue = value.substring(0, lastDecimalSeparatorIndex).replace(/[^\d]/g, '')
      cleanedValue += value.substring(lastDecimalSeparatorIndex) // Giữ lại dấu phân cách thập phân cuối cùng
      // Thay thế dấu chấm thành dấu phẩy nếu dấu phân cách cuối cùng là dấu chấm
      if (cleanedValue.endsWith('.')) {
        cleanedValue = cleanedValue.slice(0, -1) + ','
      }
      // Chuyển đổi chuỗi thành số
      let parsedNumber = parseFloat(cleanedValue.replace(',', '.'))
      // Trả về giá trị rỗng nếu không phải số
      return isNaN(parsedNumber) ? '' : parsedNumber
    }
    // Nếu không tìm thấy dấu phân cách thập phân, chỉ loại bỏ các ký tự không phải số
    let cleanedValue = value.replace(/[^\d]/g, '')
    let parsedNumber = parseFloat(cleanedValue)
    // Trả về giá trị rỗng nếu không phải số
    return isNaN(parsedNumber) ? '' : parsedNumber
  }
}

const autoCorrectedDatePipe = (conformedValue: any) => {
  const [day, month, year] = conformedValue.split('/').map(Number)

  if (day > 31 || day < 1) {
    return false
  }
  if (month > 12 || month < 1) {
    return false
  }
  return conformedValue
}

function removeNullFieldsMedicalRecordCommon(obj: any) {
  for (const key in obj) {
    if (
      obj[key] === null ||
      obj[key] === 'null' ||
      obj[key] === '' ||
      obj[key]?.length === 0 ||
      obj[key] === 'undefined' ||
      obj[key] === undefined
    ) {
      delete obj[key]
    }
  }
}

function listTrangThaiDoiSoat(value: any) {
  switch (value) {
    case 1:
      return renderRowTrangThai(
        '#e5eaef',
        '#667185',
        '#667185',
        'Chưa thực hiện',
        false,
        'phieuThanhToan'
      )
    case 2:
      return renderRowTrangThai(
        '#fdf5e6',
        '#e18309',
        '#e18309',
        'Đang thực hiện',
        false,
        'phieuThanhToan'
      )
    case 3:
      return renderRowTrangThai(
        '#E8F7EF',
        '#39AC6D',
        '#39AC6D',
        'Hoàn thành',
        false,
        'phieuThanhToan'
      )
  }
}
const phanLoaiKCBList = [
  {
    value: null,
    label: 'Tất cả',
  },
  { value: '1', label: 'Điều trị nội trú' },
  { value: '2', label: 'Điều trị ngoại trú' },
  { value: '3', label: 'Tai nạn' },
  { value: '4', label: 'Thai sản sinh thường' },
  { value: '5', label: 'Thai sản sinh mổ' },
  { value: '6', label: 'Biến chứng thai sản' },
]
// const trangThaiNguoiDungMobile : any = [
//   {label:'Đăng ký chưa thành công',value:'00'},
//   {label:'Đang hoạt động',value:'10'},
//   {label:'Bị khóa',value:'20'},
//   {label:'Đã reset',value:'30'},
//   {label:'Đã xóa',value:'99'},
// ]
function trangThaiNguoiDungMobile(value: any) {
  switch (value) {
    case '00':
      return renderRowTrangThai(
        '#E8F7EF',
        '#366AE2',
        '#366AE2',
        'Đăng ký chưa thành công',
        false,
      )
    case '10':
      return renderRowTrangThai(
        '#E8F7EF',
        '#39AC6D',
        '#39AC6D',
        'Đang hoạt động',
        false,
      )

    case '20':
      return renderRowTrangThai(
        '#FFF3EB',
        '#FC6B03',
        '#FC6B03',
        'Bị khóa',
        false,
      )

    case '30':
      return renderRowTrangThai(
        '#FCEEED',
        '#E14337',
        '#E14337',
        'Đã reset',
        false
      )
    case '99':
      return renderRowTrangThai('#FCEEED', '#C8981E', '#C8981E', 'Đã xóa', false,)

  }
}


function renderTrangThaiDuyetHoSo(value: any) {
  switch (value) {
    case 1:
      return renderRowTrangThai('#FFF3EB', '#FC6B03', '#FC6B03', 'Chờ duyệt')
    case 2:
      return renderRowTrangThai('#E8F7EF', '#39AC6D', '#39AC6D', 'Đã duyệt')
    case 3:
      return renderRowTrangThai('#FCEEED', '#E14337', '#E14337', 'Huỷ duyệt')
  }
}

function formatMilisecondToString(milisecond: any, type: string = "DD/MM/YYYY") {
  if (milisecond) {
    return moment(milisecond).format(type)
  }
  return ""
}


function renderTrangThaiBaoCaoQuyBT(value: any) {
  switch (value) {
    case 'Bổ sung ngay':
      return renderRowTrangThaiNotDotted(
        '#ffd5d5',
        '#cf3434',
        'Bổ sung ngay',
        null, null
      )

    case 'Cần xem xét':
      return renderRowTrangThaiNotDotted(
        '#e2f0d9',
        '#385723',
        'Cần xem xét',
        null, null
      )
  }
}


const listLoaiThoiGian = [
  {
    label: 'Thời gian giải quyết bồi thường',
    value: 'THOIGIANXULY',
  },
  {
    label: 'Thời gian phản hồi bổ sung hồ sơ',
    value: 'THOIGIANPHAIHOIBOSUNG',
  },
  {
    label: 'Thời gian thanh toán',
    value: 'THOIGIANTHANHTOAN',
  },
]

const checkColorDisable = (check: boolean) => {
  if (check) {
    return "#d3dce4"
  } else {
    return "#99a6b8"
  }
}

function renderTrangThaiPhanCap(value: number) {
  switch (value) {
    case 1:
      return renderRowTrangThai('#FFFFFF', '#366AE2', '#366AE2', 'Đang xử lý')
    case 2:
      return renderRowTrangThai('#FCEEED', '#E14337', '#E14337', 'Từ chối bồi thường')
    case 3:
      return renderRowTrangThai('#FFFFFF', '#366AE2', '#366AE2', 'Chờ duyệt nội bộ')
    case 4:
      return renderRowTrangThai('#FFFFFF', '#366AE2', '#366AE2', 'Chờ duyệt phân cấp')
    case 5:
      return renderRowTrangThai('#E8F7EF', '#39AC6D', '#39AC6D', 'Đã duyệt phân cấp')
    case 6:
      return renderRowTrangThai('#FCEEED', '#E14337', '#E14337', 'Từ chối duyệt phân cấp')
    case 7:
      return renderRowTrangThai('#FFFFFF', '#366AE2', '#366AE2', 'Chờ thanh toán')
    case 8:
      return renderRowTrangThai('#FCEEED', '#E14337', '#E14337', 'Từ chối thanh toán NDBH')
    case 9:
      return renderRowTrangThai('#E8F7EF', '#39AC6D', '#39AC6D', 'Đã thanh toán')
    case 10:
      return renderRowTrangThai('#E8F7EF', '#39AC6D', '#39AC6D', 'Đã duyệt nội bộ')
      
  }
}

export {
  apiDownloadFileCustom, autoCorrectedDatePipe, btnExport, calculatedGroupingIsDisabled,
  calculateIsAllDataSelected, ChangeText, checkColorDisable, containsOnlyNumbers, containsVietnameseCharacters, convertKyQT, convertToTimestamp, createResponseContext, createToastMessage, CurrencyDisplay,
  CurrencyDisplayHopDong,
  CurrencyDisplayHopDongNguoiDuocBH, formatCurrency, formatCurrencyComma, formatDateTime, formatMilisecondToString, formatNumberNghinDongChart, formatStringToMilisecond,
  formattedNumber, formattedNumberDot, formattedNumberToVND, getNgayThoiGianCuoiNgay, groupingOnSelect,
  groupingOnSelectAll, handleError, isNotEmpty, listDoiTuongThanhToan, listDoiTuongThanhToanNew, listLoaiHoSo, listLoaiThoiGian, listTinhTrangHS, listTrangThaiDoiSoat, listTrangThaiPhieu, listTrangThaiThanhToan, loadingTemplate, loaiKenhKhaiThac, paginationOptions, parseFormattedNumberCopy, parseRequestQuery, phanLoaiKCBList, phuongThucBoiThuongDanhSach, removeNullFields, removeNullFieldsMedicalRecordCommon, renderCanhBaoChiPhi, renderDoiTuongThanhToan, renderGender, renderGioiTinh, renderKQDT, RenderLimitText, renderLoaiHoSoThanhToan, renderLoaiKcb, renderLoaiKenhKhaiThac,
  renderLoaiPhuongThucBoiThuong, renderLyDoVaoVien, renderRowTrangThai, renderTienTrinhHoSoHSTTTT, renderTrangThaiBaoCaoQuyBT, renderTrangThaiDTBL, renderTrangThaiDuyetHoSo, renderTrangThaiHoanQuy, renderTrangThaiHoSo, renderTrangThaiHoSo_canhBaoChiPhi, renderTrangThaiHoSo_chitiethskcb, renderTrangThaiHoSoTienTrinh, renderTrangThaiHoSoTTTTValue, renderTrangThaiHSTTTT, renderTrangThaiNguoiDung, renderTrangThaiPhanCap, renderTrangThaiPhieu, renderTrangThaiPhieuThanhToan, renderTrangThaiThanhToan, renderTrangThaiThanhToanDanhSach, renderTTRV, reverseNgaySinh, reverseNumber, reverseNumberNotSeconds, stringifyRequestQuery, trangThaiNguoiDungMobile, trangThaiPheDuyet, trangThaiThanhToan, updatePaginationInfo, useDebounce
}
